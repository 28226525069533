import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { useLoginWithRedirect } from "@frontegg/react";
import { Descope } from "@descope/react-sdk";
import { updateLastLogin } from "../../redux/features/client-main/client-list-slice";
import { useAppDispatch } from "../../redux/store";

const Login: React.FC = () => {
  // const loginWithRedirect = useLoginWithRedirect();
  const naviate = useNavigate();
  const dispatch = useAppDispatch();

  const onLoginSuccess = async (email: any) => {
    await dispatch(updateLastLogin({ email: email }));
    naviate("/home");
  };

  // useEffect(() => {
  //   loginWithRedirect();
  // }, [loginWithRedirect]);

  return (
    <Descope
      flowId="otp-sign-in-parent"
      onSuccess={(e) => {
        onLoginSuccess(e.detail.user?.email);
      }}
      onError={(e) => console.log("Could not log in!", e)}
    />
  );
};

export default Login;
